import React from "react";
import {Card} from "baseui/card";
import {Block} from "baseui/block";
import {LabelMedium} from "baseui/typography";


export interface InfoSection {
    title: string,
    element: React.ReactElement,
}


export const GenericInfoCard: React.FC<{
    infoSections: InfoSection[],
    content: React.ReactElement,
}> = ({infoSections, content}) => {
    return (
        <Card overrides={{Root: {style: {width: '100%'}}}}>

            <Block style={{display: "flex", justifyContent: "space-between"}}>
                <Block style={{
                    padding: 20,
                    paddingRight: 54,
                }}>

                    {infoSections.map((section, index) => (
                        <Block style={{marginTop: index == 0 ? 0 : 20}}>
                            <LabelMedium style={{marginBottom: 2}}>{section.title}</LabelMedium>
                            {section.element}
                        </Block>
                    ))}

                </Block>

                <Block
                    style={{
                        marginTop: 6,
                        width: "fit-content",
                    }}>
                    {content}
                </Block>
            </Block>

        </Card>
    )
}


export const StatsInfoCard: React.FC<{
    infoSections: InfoSection[],
    tiles: React.ReactElement[],
    tileColumnCount: number,
}> = ({infoSections, tiles, tileColumnCount}) => {
    return <GenericInfoCard infoSections={infoSections} content={(
        <Block
            style={{
                display: "grid",
                gridTemplateColumns: new Array(tileColumnCount).fill("1fr").join(" "),
                columnGap: "10px",
                rowGap: "10px",
            }}>
            {tiles}
        </Block>
    )}/>
}

import {APIClient} from "../../api/APIClient";
import {useQuery} from "react-query";
import {SystemOverview} from "./types";

export const useSystemOverview = () => {
    const apiClient = new APIClient();

    return useQuery<SystemOverview, Error>(
        ["systemOverview"],
        () => apiClient.get(`admin-dashboard/overview/`).then(
            data => data.json()
        ).then(
            data => data as SystemOverview
        ),
        {
            staleTime: 1 * 1000, // 10 seconds in milliseconds
            refetchInterval: 1 * 1000, // Refetch every 10 seconds
            refetchOnWindowFocus: false, // Disable refetch on window focus
        }
    );
};

import {
    Navigate,
    Route,
    Routes,
} from "react-router-dom";
import {Navbar} from "../../components/Navbar/Navbar";
import {OverviewPage} from "../OverviewPage/OverviewPage";
import React from "react";
import {Block} from "baseui/block";




export function Dashboard() {

    return (
        <Block style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <Navbar />
            <Routes >
                <Route path="/" element={<OverviewPage />} />
                <Route
                    path="*"
                    element={<Navigate to="/" replace={true} />}
                />
            </Routes>
        </Block>

    )
}

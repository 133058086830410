import {Block} from "baseui/block";
import {LabelLarge, LabelMedium, LabelSmall, MonoLabelMedium, ParagraphMedium, ParagraphSmall} from "baseui/typography";
import {Accordion, Panel} from "baseui/accordion";
import {Badge, COLOR, HIERARCHY, SHAPE} from "baseui/badge";
import React from "react";
import {OverviewSection} from "../../../components/OverviewSection/OverviewSection";
import {InfoSection, GenericInfoCard} from "../../../components/GenericInfoCard/GenericInfoCard";
import {useSystemOverview} from "../../../services/stats/actions";
import {Skeleton} from "baseui/skeleton";
import {GPU, ProcessingNode} from "../../../services/stats/types";
import {convertBytes} from "../../../utils/misc";
import {Card, StyledBody} from "baseui/card";
import {formatDistanceToNowStrict} from "date-fns";


const healthyBadge = <Badge
    content="Healthy"
    hierarchy={HIERARCHY.secondary}
    shape={SHAPE.rectangle}
    color={COLOR.positive}
/>


const ProcessingNodeGPUCard: React.FC<{ gpu: GPU }> = ({gpu}) => {
    return (
        <Card>
            <StyledBody>
                <Block style={{display: "flex"}}>
                    <LabelMedium $style={{marginRight: "8px"}}>{gpu.vendor} {gpu.product}</LabelMedium>
                    {
                        gpu.is_default && (
                            <Badge
                                content="Default"
                                hierarchy={HIERARCHY.secondary}
                                shape={SHAPE.rectangle}
                                color={COLOR.accent}
                            />
                        )
                    }
                </Block>

                <Block style={{display: "flex"}}>
                    <Block style={{marginRight: 60}}>
                        <Block style={{marginTop: 20}}>
                            <LabelSmall style={{marginBottom: 2}}>PCI bus</LabelSmall>
                            <ParagraphSmall style={{margin: 0}}>{gpu.businfo}</ParagraphSmall>
                        </Block>

                        <Block style={{marginTop: 20}}>
                            <LabelSmall style={{marginBottom: 2}}>DRM render node</LabelSmall>
                            <ParagraphSmall style={{margin: 0}}>{gpu.device_render_link}</ParagraphSmall>
                        </Block>
                    </Block>

                    <Block>
                        <Block style={{marginTop: 20}}>
                            <LabelSmall style={{marginBottom: 2}}>Hardware encoding</LabelSmall>
                            <div style={{display: "flex"}}>
                                {gpu.support.encoding.map(enc => (
                                    <React.Fragment>
                                        <Badge
                                            content={enc.codec_label}
                                            hierarchy={HIERARCHY.secondary}
                                            shape={SHAPE.rectangle}
                                            color={enc.is_supported ? COLOR.positive : COLOR.negative}
                                        />
                                        <div style={{width: 6}}></div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </Block>

                        <Block style={{marginTop: 20}}>
                            <LabelSmall style={{marginBottom: 2}}>Hardware decoding</LabelSmall>
                            <div style={{display: "flex"}}>
                                {gpu.support.decoding.map(enc => (
                                    <React.Fragment>
                                        <Badge
                                            content={enc.codec_label}
                                            hierarchy={HIERARCHY.secondary}
                                            shape={SHAPE.rectangle}
                                            color={enc.is_supported ? COLOR.positive : COLOR.negative}
                                        />
                                        <div style={{width: 6}}></div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </Block>
                    </Block>
                </Block>
            </StyledBody>
        </Card>
    )
}


const ProcessingNodeCard: React.FC<{
    node: ProcessingNode
}> = ({node}) => {

    const [ramValue, ramUnit] = convertBytes(node.system_info.memory);
    const formattedRamValue = ramValue % 1 === 0 ? ramValue.toString() : ramValue.toFixed(1);

    const infoSections: InfoSection[] = [
        {
            title: "Node ID",
            element: <MonoLabelMedium>{node.id}</MonoLabelMedium>,
        },
        {
            title: "Status",
            element: healthyBadge,
        },
        {
            title: "CPU",
            element: <ParagraphMedium
                style={{margin: 0}}>{node.system_info.cpu} ({node.system_info.core_count} cores)</ParagraphMedium>,
        },
        {
            title: "Architecture",
            element: <ParagraphMedium style={{margin: 0}}>{node.system_info.architecture}</ParagraphMedium>,
        },
        {
            title: "RAM",
            element: <ParagraphMedium style={{margin: 0}}>{formattedRamValue} {ramUnit}</ParagraphMedium>,
        },
        {
            title: "Started",
            element: <ParagraphMedium
                style={{margin: 0}}>{formatDistanceToNowStrict(node.started_at * 1000, {addSuffix: true})}</ParagraphMedium>,
        },
    ];

    return <GenericInfoCard
        infoSections={infoSections}
        content={(
            <Block style={{padding: 20, width: "600px"}}>
                <LabelMedium>Hardware acceleration:</LabelMedium>
                {!!node.gpus.length ? (
                    <Block>
                        {
                            node.gpus.map(gpu => (
                                <Block style={{marginTop: 10}}>
                                    <ProcessingNodeGPUCard gpu={gpu}/>
                                </Block>
                            ))
                        }
                    </Block>
                ) : (
                    <Block>
                        Not available
                    </Block>
                )}

            </Block>
        )}
    />
}


export const ProcessingSection = () => {
    const {data: overview} = useSystemOverview();

    if (!overview) {
        return <Skeleton height="250px" width="100%" animation={true}/>
    }
    const stats = overview.processing;

    return (
        <OverviewSection title={"Processing"}>

            <Block style={{marginTop: 30}}>
                <Block style={{display: "flex", alignItems: "center"}}>
                    <LabelLarge style={{marginRight: 30}}>Nodes:</LabelLarge>
                    <Badge
                        content="(only online nodes are visible)"
                        hierarchy={HIERARCHY.secondary}
                        color={COLOR.primary}
                    />
                </Block>

                <Accordion
                    onChange={({expanded}) => console.log(expanded)}
                    accordion={false}
                    initialState={{expanded: stats.nodes.map(node => node.id)}}
                >
                    {stats.nodes.map(node => (
                        <Panel
                            title={
                                <div style={{"display": "flex"}}>
                                    <MonoLabelMedium style={{marginRight: 16}}>
                                        {node.id}
                                    </MonoLabelMedium>
                                    {healthyBadge}
                                </div>
                            }
                            key={node.id}
                        >
                            <ProcessingNodeCard node={node}/>
                        </Panel>
                    ))}
                </Accordion>
            </Block>
        </OverviewSection>
    )
}

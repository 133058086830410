import React from "react";
import {AppNavBar} from "baseui/app-nav-bar";

export const Navbar: React.FC = () => {

    return (
        <AppNavBar
            title="Granada Control Panel"
            // mainItems={mainItems}
            // onMainItemSelect={handleMainItemSelect}
        />
    );
}

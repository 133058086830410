import {Block} from "baseui/block";
import {LabelLarge, MonoLabelMedium} from "baseui/typography";
import {TbDisc, TbDownload, TbFile, TbOutbound, TbServer2} from "react-icons/tb";
import {Accordion, Panel} from "baseui/accordion";
import {Badge, COLOR, HIERARCHY, SHAPE} from "baseui/badge";
import {StyledLink} from "baseui/link";
import React from "react";
import {OverviewSection} from "../../../components/OverviewSection/OverviewSection";
import {NumberStatsTile, ProgressStatsTile} from "../../../components/tiles/Tile";
import {StatsInfoCard, InfoSection} from "../../../components/GenericInfoCard/GenericInfoCard";
import {useSystemOverview} from "../../../services/stats/actions";
import {Skeleton} from "baseui/skeleton";
import {StorageNode, StorageNodeStatus} from "../../../services/stats/types";
import {convertBytes} from "../../../utils/misc";


const mapStorageNoteStatusToBadge = (status: StorageNodeStatus) => {
    if (status === StorageNodeStatus.ONLINE) {
        return <Badge
            content="Healthy"
            hierarchy={HIERARCHY.secondary}
            shape={SHAPE.rectangle}
            color={COLOR.positive}
        />
    } else if (status == StorageNodeStatus.OFFLINE) {
        return <Badge
            content="Offline"
            hierarchy={HIERARCHY.secondary}
            shape={SHAPE.rectangle}
            color={COLOR.negative}
        />
    } else {
        throw Error("Not implemented")
    }
}


const StorageNodeCard: React.FC<{
    node: StorageNode
}> = ({node}) => {
    const tiles: React.ReactElement[] = []

    if (node.transfer_out != null) {
        const [value, unit] = convertBytes(node.transfer_out);
        tiles.push(
            <NumberStatsTile
                title="Outbound traffic"
                value={value}
                unit={unit}
                icon={<TbOutbound color={"red"}/>}
            />
        )
    }

    if (node.total_space != null) {
        const [value, unit] = convertBytes(node.total_space);
        tiles.push(
            <NumberStatsTile
                title="Machine disk size"
                value={value}
                unit={unit}
                icon={<TbDisc/>}
            />
        )
    }

    if (node.used_space != null && node.total_space != null) {
        const [value, unit] = convertBytes(node.used_space);
        tiles.push(
            <ProgressStatsTile
                title="Machine disk utilization"
                value={value}
                unit={unit}
                percentage={node.used_space / node.total_space}
            />
        )
    }

    if (node.files_space != null && node.free_space != null) {
        const percentage = node.files_space / (node.free_space + node.files_space);
        tiles.push(
            <ProgressStatsTile
                title="Files free space utilization"
                value={percentage}
                unit="%"
                percentage={percentage}
            />
        )
    }

    if (node.files_space != null) {
        const [value, unit] = convertBytes(node.files_space);
        tiles.push(
            <NumberStatsTile
                title="Files total size"
                value={value}
                unit={unit}
                icon={<TbFile/>}
            />
        )
    }

    if (node.files != null) {
        tiles.push(
            <NumberStatsTile
                title="Files"
                value={node.files}
                unit={null}
                icon={<TbFile/>}
            />
        )
    }

    if (node.downloads != null) {
        tiles.push(
            <NumberStatsTile
                title="File downloads"
                value={node.downloads}
                unit={null}
                icon={<TbDownload/>}
            />
        )
    }

    const infoSections: InfoSection[] = [
        {
            title: "Node ID",
            element: <MonoLabelMedium>{node.id}</MonoLabelMedium>,
        },
        {
            title: "Address",
            element: (
                node.address ? (
                    <StyledLink href={node.address} target="_blank">
                        <MonoLabelMedium>{node.address}</MonoLabelMedium>
                    </StyledLink>
                ) : (
                    <MonoLabelMedium>-</MonoLabelMedium>
                )
            ),
        },
        {
            title: "Status",
            element: mapStorageNoteStatusToBadge(node.state),
        },
    ];

    return <StatsInfoCard
        infoSections={infoSections}
        tiles={tiles}
        tileColumnCount={4}
    />
}


export const StorageOrUploadSection: React.FC<{title: string, useAsStorage: boolean}> = ({title, useAsStorage}) => {
    const {data: overview} = useSystemOverview();

    if (!overview) {
        return <Skeleton height="250px" width="100%" animation={true}/>
    }

    const stats = useAsStorage ? overview.storage : overview.uploads;

    const totalEgress = stats.nodes.some(node => node.transfer_out != null)
        ? stats.nodes.reduce((sum, node) => sum + (node.transfer_out || 0), 0)
        : null;

    const totalFiles = stats.nodes.some(node => node.files != null)
        ? stats.nodes.reduce((sum, node) => sum + (node.files || 0), 0)
        : null;

    const totalDownloads = stats.nodes.some(node => node.downloads != null)
        ? stats.nodes.reduce((sum, node) => sum + (node.downloads || 0), 0)
        : null;

    const totalSpace = stats.nodes.some(node => node.total_space != null)
        ? stats.nodes.reduce((sum, node) => sum + (node.total_space || 0), 0)
        : null;

    const totalUsedSpace = stats.nodes.some(node => node.used_space != null)
        ? stats.nodes.reduce((sum, node) => sum + (node.used_space || 0), 0)
        : null;

    return (
        <OverviewSection title={title}>
            <Block style={{marginTop: 30}}>
                <LabelLarge>Overview: </LabelLarge>
                <Block style={{marginTop: 6, display: "flex"}}>
                    <NumberStatsTile
                        title="Nodes"
                        value={stats.nodes.length}
                        unit={null}
                        icon={<TbServer2/>}
                    />
                    {
                        totalEgress != null && (
                            <React.Fragment>
                                <div style={{width: 10}}/>
                                <NumberStatsTile
                                    title="Outbound traffic"
                                    value={convertBytes(totalEgress)[0]}
                                    unit={convertBytes(totalEgress)[1]}
                                    icon={<TbOutbound color={"red"}/>}
                                />
                            </React.Fragment>
                        )
                    }
                    {(totalSpace != null && totalUsedSpace != null) && (
                        <React.Fragment>
                            <div style={{width: 10}}/>
                            <ProgressStatsTile
                                title="Storage utilization"
                                value={convertBytes(totalUsedSpace)[0]}
                                unit={convertBytes(totalUsedSpace)[1]}
                                percentage={totalUsedSpace / totalSpace}
                            />
                        </React.Fragment>
                    )}
                    {
                        totalFiles != null && (
                            <React.Fragment>
                                <div style={{width: 10}}/>
                                <NumberStatsTile
                                    title="Files"
                                    value={totalFiles}
                                    unit={null}
                                    icon={<TbFile/>}
                                />
                            </React.Fragment>
                        )
                    }
                    {
                        totalDownloads != null && (
                            <React.Fragment>
                                <div style={{width: 10}}/>
                                <NumberStatsTile
                                    title="File downloads"
                                    value={totalDownloads}
                                    unit={null}
                                    icon={<TbDownload/>}
                                />
                            </React.Fragment>
                        )
                    }
                </Block>
            </Block>

            <Block style={{marginTop: 50}}>
                <Block style={{display: "flex", alignItems: "center"}}>
                    <LabelLarge style={{marginRight: useAsStorage ? 0 : 30}}>Nodes: </LabelLarge>
                    {!useAsStorage && <Badge
                        content="(only online nodes are visible)"
                        hierarchy={HIERARCHY.secondary}
                        color={COLOR.primary}
                    />}
                </Block>
                <Accordion
                    onChange={({expanded}) => console.log(expanded)}
                    accordion={false}
                    initialState={{expanded: stats.nodes.map(node => node.id)}}
                >
                    {stats.nodes.map(node => (
                        <Panel
                            title={
                                <div style={{"display": "flex"}}>
                                    <MonoLabelMedium style={{marginRight: 16}}>
                                        {node.id}
                                    </MonoLabelMedium>
                                    {mapStorageNoteStatusToBadge(node.state)}
                                </div>
                            }
                            key={node.id}
                        >
                            <StorageNodeCard node={node}/>
                        </Panel>
                    ))}
                </Accordion>
            </Block>
        </OverviewSection>
    )
}

export interface CoreStats {
    address: string;
    videos: number;
    platforms: number;
    users: number;
}


export enum StorageNodeStatus {
    ONLINE = "online",
    OFFLINE = "offline",
}


export interface StorageNode {
    id: string;
    state: StorageNodeStatus;
    address?: string;
    total_space?: number;
    used_space?: number;
    free_space?: number;
    downloads?: number;
    transfer_out?: number;
    files?: number;
    files_space?: number;
}

export interface StorageStats {
    nodes: StorageNode[];
}

export interface ProcessingNode {
    state: string;
    id: string;
    currently_processing: string;
    type: string;
    gpus: GPU[];
    started_at: number;
    system_info: SystemInfo;
}

export interface GPU {
    product: string;
    vendor: string;
    businfo: string;
    device_render_link: string;
    support: GPUSupport;
    is_default: boolean;
}

export interface GPUSupport {
    encoding: CodecSupport[];
    decoding: CodecSupport[];
    score: number;
}

export interface CodecSupport {
    codec: string;
    codec_label: string;
    is_supported: boolean;
}

export interface SystemInfo {
    architecture: string;
    cpu: string;
    core_count: number;
    memory: number;
}

export interface ProcessingStats {
    nodes: ProcessingNode[];
}

export interface UploadStats {
    nodes: StorageNode[];
}

export interface SystemOverview {
    core: CoreStats;
    storage: StorageStats;
    uploads: UploadStats;
    processing: ProcessingStats;
}

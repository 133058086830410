import React from "react";
import {Card, StyledBody} from "baseui/card";
import {LabelLarge, LabelMedium, LabelSmall} from "baseui/typography";
import {ProgressBarRounded} from "baseui/progress-bar";


const GenericStatsTile: React.FC<{
    title: string,
    content: React.ReactNode,
}> = ({title, content}) => {
    return (
        <Card overrides={{Root: {style: {width: '163.5px', maxHeight: "128px"}}}}>
            <StyledBody style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                {content}
                <LabelMedium style={{marginTop: 16, textAlign: "center"}}>{title}</LabelMedium>
            </StyledBody>
        </Card>
    )
}

export const NumberStatsTile: React.FC<{
    title: string,
    value: number,
    unit: string | null,
    icon: React.ReactElement,
}> = ({title, value, unit, icon}) => {
    const styledIcon = React.cloneElement(icon, {style: {marginLeft: 4}});
    const formattedValue = value % 1 === 0 ? value.toString() : value.toFixed(1);
    return <GenericStatsTile title={title} content={
        <LabelLarge
            style={{lineHeight: "36px", display: "flex", alignItems: "center"}}
        >
            {formattedValue}
            {!!unit && ` ${unit}`}
            {styledIcon}
        </LabelLarge>
    }/>
}


export const ProgressStatsTile: React.FC<{
    title: string,
    value: number,
    percentage: number,
    unit: string | null,
}> = ({title, value, percentage, unit,}) => {
    const formattedValue = value % 1 === 0 ? value.toString() : value.toFixed(2);
    return <GenericStatsTile title={title} content={
        <ProgressBarRounded progress={percentage} animate={false} overrides={
            {
                Text: {
                    component: () => (
                        <LabelSmall>
                            {formattedValue}
                            {!!unit && ` ${unit}`}
                        </LabelSmall>
                    )
                }
            }
        }/>
    }/>
}


import React from "react";
import {CenteredDiv} from "../../components/CenteredDiv/CenteredDiv";
import {Block} from "baseui/block";
import {StorageOrUploadSection} from "./sections/StorageOrUploadSection";
import {CoreSection} from "./sections/CoreSection";
import {ProcessingSection} from "./sections/ProcessingSection";


export function OverviewPage() {
    return (
        <CenteredDiv>
            <Block style={{"width": "80%", "marginTop": "50px", "maxWidth": "1280px"}}>
                <CoreSection />
                <div style={{height: 60}} />

                <StorageOrUploadSection title={"Storage"} useAsStorage={true}/>
                <div style={{height: 60}} />

                <StorageOrUploadSection title={"Upload"} useAsStorage={false}/>
                <div style={{height: 60}} />

                <ProcessingSection/>
                <div style={{height: 60}} />
            </Block>
        </CenteredDiv>
    )
}

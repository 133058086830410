export const convertBytes = (bytes: number): [number, string] =>  {
    if (bytes < 1000) {
        return [bytes, 'B']
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let unitIndex = 0;

    while (bytes >= 1000 && unitIndex < units.length) {
        bytes /= 1024;
        unitIndex++;
    }

    return [bytes, units[unitIndex - 1]]
}
import React from 'react';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {BaseProvider, LightTheme, DarkTheme} from 'baseui';
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {Dashboard} from "../Dashboard/Dashboard";

const engine = new Styletron();
const queryClient = new QueryClient()

function App() {
    return (
        <BrowserRouter>
            <StyletronProvider value={engine}>
                <BaseProvider theme={LightTheme}>
                    <QueryClientProvider client={queryClient}>
                        <Dashboard/>
                    </QueryClientProvider>
                </BaseProvider>
            </StyletronProvider>
        </BrowserRouter>
    );
}

export default App;

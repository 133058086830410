import {MonoLabelMedium} from "baseui/typography";
import {StyledLink} from "baseui/link";
import {Badge, COLOR, HIERARCHY, SHAPE} from "baseui/badge";
import {OverviewSection} from "../../../components/OverviewSection/OverviewSection";
import React from "react";
import {NumberStatsTile} from "../../../components/tiles/Tile";
import {TbBuildingStore, TbMovie, TbUser} from "react-icons/tb";
import {StatsInfoCard, InfoSection} from "../../../components/GenericInfoCard/GenericInfoCard";
import {useSystemOverview} from "../../../services/stats/actions";
import {CoreStats} from "../../../services/stats/types";
import {Skeleton} from "baseui/skeleton";


const CoreNodeCard: React.FC<{
    stats: CoreStats
}> = ({stats}) => {

    const tiles = [
        <NumberStatsTile
            title="Videos"
            value={stats.videos}
            unit={null}
            icon={<TbMovie/>}
        />,
        <NumberStatsTile
            title="Platforms"
            value={stats.platforms}
            unit={null}
            icon={<TbBuildingStore/>}
        />,
        <NumberStatsTile
            title="Users"
            value={stats.users}
            unit={null}
            icon={<TbUser/>}
        />,
    ]

    const infoSections: InfoSection[] = [
        {
            title: "Address",
            element: (
                <StyledLink href={stats.address} target="_blank">
                    <MonoLabelMedium>{stats.address}</MonoLabelMedium>
                </StyledLink>
            ),
        },
        {
            title: "Status",
            element: (
                <Badge
                    content="Healthy"
                    hierarchy={HIERARCHY.secondary}
                    shape={SHAPE.rectangle}
                    color={COLOR.positive}
                />
            ),
        },
    ];

    return <StatsInfoCard
        infoSections={infoSections}
        tiles={tiles}
        tileColumnCount={3}
    />
}


export const CoreSection = () => {
    const {data: overview} = useSystemOverview();
    return (
        <OverviewSection title={"Core"}>
            {
                overview ? (
                    <CoreNodeCard stats={overview.core}/>
                ) : (
                    <Skeleton height="148px" width="100%" animation={true}/>
                )
            }
        </OverviewSection>
    )
}

import {Constant} from "../consts";


export class APIClient {
    baseUrl: string

    constructor() {
        this.baseUrl = Constant.BACKEND_API_BASE_URL;
    }

    buildUrl(path: string): string{
        return this.baseUrl + path;
    }

    async makeRequest(url: string, method: string, body: string|null = null){
        const fullUrl = this.buildUrl(url);

        const headers: { [key: string]: string } = {
            'Content-Type': 'application/json',
            'Dashboard-Access-Token': '029423daebddf3eac343cdb3e6d1b4b021dad6427c276f1005e251d1aa0eb5da',
        };

        console.log(`BackendAPI ${method} request ${fullUrl} with payload ${body}`)

        return await fetch(fullUrl, {
            method: method,
            headers: headers,
            body: body,
        });
    };

    async get(url: string){
        return await this.makeRequest(url, "GET", null);
    }

    async post(url: string, payload: Object|null){
        const body = payload ? JSON.stringify(payload) : null;
        return await this.makeRequest(url, 'POST', body);
    }

    async put(url: string, payload: Object|null){
        const body = payload ? JSON.stringify(payload) : null;
        return await this.makeRequest(url, 'PUT', body);
    }
}
